import $ from '../vendor/jquery';
import { getCurrentBreakpoint } from '../includes/utils';

function optimizeGrid($container) {
    const breakpoint = getCurrentBreakpoint(),
        currentState = $container.data('state') || 'original',
        newState = ('md' === breakpoint) ? 'optimized' : 'original';

    if (newState === currentState) {
        return;
    }

    if ('optimized' === newState) {
        let $columns = $container.children(),
            optimizedSorting = [],
            currentRowWidth = 0,
            stack = $columns.get(),
            stackCopy;

        while (0 !== stack.length) {
            stackCopy = [];
            stack.forEach(function(column){
                stackCopy.push(column)
            });

            stackCopy.forEach(function (column) {
                const columnWidth = 'small' === $(column).data('width') ? 1 : 2;

                // We have enough space for the current column
                if (columnWidth + currentRowWidth <= 2) {
                    // Add the column to the sort-index and remove it from the stack so it is not processed again
                    optimizedSorting.push(column);
                    stack.splice(stack.indexOf(column), 1);

                    // Add the width of the added column to the current row
                    currentRowWidth += columnWidth;

                    // Limit is reached, start another row
                    if (2 <= currentRowWidth) {
                        currentRowWidth = 0;
                    }
                }
            });

            // We were not able to add another column to the sort-index
            // -> This happens only if we need another small column to fill a row, but we have only large columns left
            // -> Append the remaining elements 1:1 to the sort index
            if (stackCopy.length === stack.length) {
                optimizedSorting = optimizedSorting.concat(stack);
                stack = [];
            }
        }

        $columns
            .sort(function (a, b) {
                return optimizedSorting.indexOf(a) > optimizedSorting.indexOf(b) ? 1 : -1;
            })
            .appendTo($container);
    } else {
        restoreGrid($container);
    }

    $container.data('state', newState);
}

function restoreGrid($container) {
    $container.children()
        .sort(function (a, b) {
            const indexA = $(a).data('originalIndex'),
                indexB = $(b).data('originalIndex');

            return indexA > indexB ? 1 : -1;
        })
        .appendTo($container);
}

$(function () {
    $('[data-widget="grid"]').each(function () {
        const $container = $(this);

        $container.children().each(function (index, column) {
            $(column).data('originalIndex', index);
        });

        optimizeGrid($container);
        $(window).on('breakpointChange.mankiewicz', function () {
            optimizeGrid($container);
        });
    });
});
