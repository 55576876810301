const toggleShippingAddressForm = document.getElementById('shipping-same-as-billing');
if (toggleShippingAddressForm) {
    toggleShippingAddressForm.addEventListener('change', function () {
        const checked = this.checked;
        const fieldWrapper = document.getElementById('checkout-step-shipping-address');

        fieldWrapper.hidden = checked;
        [...fieldWrapper.querySelectorAll('input[data-disable-shipping], select[data-disable-shipping]')].forEach((element) => {
            element.disabled = checked;
        });
    });
}
