import $ from '../vendor/jquery';
import { isBreakpoint } from '../includes/utils';

const MODE_DESKTOP = 'desktop';
const MODE_MOBILE = 'mobile';

class Menu {
    constructor(element) {
        this.$container = $(element);
        this.$body = $('body');
        this.$window = $(window);
        this.$toggle = $(this.$container.data('toggleSelector')).first();
        this.$overlay = null;
        this.mode = null;
        this.menus = {};
    }

    initialize() {
        this._indexMenus();
        this._detectMode();
        this._bindEventHandlers();
    }

    _showOverlay(menu = null, content = null) {
        const isVisible = !!this.$overlay;

        if (isVisible) {
            this._hideOverlay(0);
        }

        const $overlay = (MODE_DESKTOP === this.mode)
            ? this._renderDesktopOverlay(menu, content)
            : this._renderMobileOverlay();

        $overlay
            .hide()
            .appendTo(this.$body)
            .height(this.$body.height())
            .fadeIn(isVisible ? 0 : 50);

        this.$overlay = $overlay;

        this.$toggle.attr('aria-expanded', 'true');
    }

    _hideOverlay(animationDuration = 50) {
        if (this.$overlay) {
            this.$overlay.fadeOut(animationDuration, () => {
                this.$overlay.remove();
                this.$container.find('.menu__item--open').removeClass('menu__item--open');
                this.$overlay = null;
                this.$toggle.attr('aria-expanded', 'false');
            });
        }
    }

    _toggleSearchForm() {
        if (0 < this.$container.find('.search--open').length) {
            this._hideSearchForm();
        } else {
            this._showSearchForm();
        }
    }

    _showSearchForm() {
        const $search = this.$container.find('.search');

        $search.addClass('search--open');
        $search.find('input').select();
    }

    _hideSearchForm() {
        this.$container.find('.search').removeClass('search--open');
    }

    _renderDesktopOverlay(menu, content = null) {
        const template = this.$container.find('[data-template="desktop-overlay"]').html();

        const $overlay = $(template);
        const $menu = this._renderMenu(menu);

        $overlay.find('.menu-overlay__column--left')
            .html($menu)
            .on('click', '[data-action="open-sub-menu"]', (event) => {
                event.preventDefault();
                event.currentTarget.blur();

                const $link = $(event.currentTarget);
                const $listItem = $link.parent('li');
                const $subMenu = $listItem.children('ul');

                if ($subMenu) {
                    $overlay.find('.menu-overlay__column--right').html(this._renderMenu(this._parseMenu($subMenu, menu.level + 1)));
                    $menu.find('.menu-overlay__item--open').removeClass('menu-overlay__item--open');
                    $listItem.addClass('menu-overlay__item--open');
                }
            });

        if (content) {
            $overlay.find('.menu-overlay__column--right').html('<div class="menu-overlay__content">' + content + '</div>');
        }

        return $overlay;
    }

    _renderMobileOverlay() {
        let menuHierarchy = [];

        const template = this.$container.find('[data-template="mobile-overlay"]').html();

        const $overlay = $(template);
        const $scrollArea = $overlay.find('.menu-overlay__scroll-area');
        const $backLink = $overlay.find('.menu-overlay__back-link');

        const reset = () => {
            menuHierarchy = [];

            $backLink.removeClass('menu-overlay__back-link--visible');

            $scrollArea.empty();

            const $searchForm = this._renderSearchForm();
            if ($searchForm) {
                $scrollArea.append($searchForm);
            }

            ['main-menu', 'meta-menu'].forEach((menu) => {
                const $menu = this._renderMenu(this.menus[menu], menu);

                $scrollArea.append($menu);
            });

            const $languageSwitcher = this._renderLanguageSwitcher();
            if ($languageSwitcher) {
                $scrollArea.append($languageSwitcher);
            }
        };

        reset();

        $overlay
            .on('click', '[data-action="close"]', () => {
                this._hideOverlay();
            })
            .on('click', '[data-action="back"]', (event) => {
                event.currentTarget.blur();

                if (1 < menuHierarchy.length) {
                    $scrollArea.html(this._renderMenu(menuHierarchy.pop()));
                } else {
                    reset();
                }
            })
            .on('click', '[data-action="open-sub-menu"]', (event) => {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.currentTarget.blur();

                const $link = $(event.currentTarget);
                const $listItem = $link.parent('li');
                const $subMenu = $listItem.children('ul');
                const $menu = $listItem.parent('ul');
                const menu = $menu.data('menu');

                if ($subMenu) {
                    $backLink.addClass('menu-overlay__back-link--visible');

                    $scrollArea.html(this._renderMenu(this._parseMenu($subMenu, menu.level + 1)));
                    $menu.find('.menu-overlay__item--open').removeClass('menu-overlay__item--open');
                    $listItem.addClass('menu-overlay__item--open');

                    menuHierarchy.push(menu);
                }
            });

        return $overlay;
    }

    _renderMenu(menu, name = null) {
        const $menu = $('<ul class="menu-overlay__items"/>');

        if (name) {
            $menu.addClass('menu-overlay__items--' + name);
        }

        if (menu.level) {
            $menu.addClass('menu-overlay__items--level-' + String(menu.level));
        }

        menu.items.forEach((item) => {
            const $item = $('<li class="menu-overlay__item"/>')
                .attr('data-type', item.type)
                .appendTo($menu);

            if ('overview' === item.type) {
                $item.addClass('menu-overlay__item--important');

                if (2 === menu.level) {
                    $item.addClass('menu-overlay__item--only-mobile');
                }
            }

            const $link = $('<a/>', item.attributes)
                .html(item.linkText)
                .appendTo($item);

            if (item.url) {
                $link.attr('href', item.url);
            }

            if (item.subMenu) {
                $link.attr('data-action', 'open-sub-menu');
                $item.addClass('menu-overlay__item--has-children');

                this._renderMenu(item.subMenu).appendTo($item);
            }
        });

        $menu.data('menu', menu);

        return $menu;
    }

    _renderLanguageSwitcher() {
        const $languageSwitcher = this.$container.find('[data-target="language-switcher"]').first();
        let menu = null;

        if (0 < $languageSwitcher.length) {
            menu = {
                items: [
                    {
                        linkText: $languageSwitcher.prev().html().trim(),
                        level: 1,
                        subMenu: {
                            items: [],
                            level: 2,
                        },
                    }
                ],
                level: 1,
            };

            $languageSwitcher.find('li').each((index, element) => {
                const $listItem = $(element);
                const $link = $listItem.children('a');

                if (0 < $link.length) {
                    menu.items[0].subMenu.items.push({
                        linkText: $link.html().trim(),
                        attributes: {
                            title: $link.attr('title'),
                            target: $link.attr('target'),
                        },
                        url: $link.attr('href'),
                        level: 2,
                    });
                }
            });
        }

        return menu
            ? this._renderMenu(menu, 'language')
            : null;
    }

    _renderSearchForm() {
        if (0 === this.$container.find('[data-target="search-form"]').length) {
            return null;
        }

        return $('<div class="menu-overlay__search search"></div>').append(this.$container.find('[data-target="search-form"]').clone());
    }

    _bindEventHandlers() {
        this.$container.on('click', '[data-action="open-sub-menu"]', (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.currentTarget.blur();

            const $link = $(event.currentTarget);
            const $listItem = $link.parent('li');
            const $subMenu = $listItem.children('ul');
            const $content = $listItem.children('div');

            if ($subMenu) {
                this._showOverlay(
                    this._parseMenu($subMenu, 2),
                    0 < $content.length ? $content.first().html() : null
                );
                $listItem.addClass('menu__item--open');
            }
        });

        this.$container.on('click', '[data-action="toggle-search-form"]', (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.currentTarget.blur();

            this._toggleSearchForm();
        });

        this.$toggle.on('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            event.currentTarget.blur();

            if (this.mode === MODE_MOBILE) {
                this._showOverlay();
            }
        });

        this.$window.on('breakpointChange.mankiewicz', () => {
            this._detectMode();
        });

        $(document).on('click', (event) => {
            if (this.$overlay
                && 0 === $(event.target).closest(this.$overlay.find('.menu-overlay__container')).length) {
                this._hideOverlay();
            }

            if (0 === $(event.target).closest(this.$container.find('.search__form')).length) {
                this._hideSearchForm();
            }
        })
    }

    _parseMenu($menu, level = 1) {
        let items = [];

        $menu.children().each((index, element) => {
            const $listItem = $(element);
            const $link = $listItem.children('a');
            const $children = $listItem.children('ul');

            if (0 < $link.length) {
                items.push({
                    type: $listItem.data('type'),
                    linkText: $link.html().trim(),
                    attributes: {
                        title: $link.attr('title'),
                        target: $link.attr('target'),
                    },
                    url: $link.attr('href'),
                    level: level,
                    subMenu: 0 < $children.length
                        ? this._parseMenu($children, level + 1)
                        : null,
                });
            }
        });

        return {
            items: items,
            level: level,
        };
    }

    _indexMenus() {
        this.$container.find('[data-target="menu"]').each((index, element) => {
            const $menu = $(element);
            const name = $menu.data('name');
            if (!name) {
                throw new Error('Every menu must have the attribute data-name');
            }

            const $list = $menu.is('ul') ? $menu : $menu.find('ul').first();

            this.menus[name] = this._parseMenu($list);
        });
    }

    _detectMode() {
        const newMode = isBreakpoint(['xs', 'sm', 'md'])
            ? MODE_MOBILE
            : MODE_DESKTOP;

        if (newMode !== this.mode) {
            this.mode = newMode;
            this._hideOverlay();
        }
    }
}

$(function () {
    $('[data-widget="menu"]').each(function () {
        const menu = new Menu(this);
        menu.initialize();

        $(this).data('menu', menu);
    });
});
