import 'picturefill';
import './vendor/jquery';
import './vendor/typo3';
import './vendor/cart';
import './includes/back-to-top';
import './includes/cookie-consent';
import './includes/forms';
import './includes/image-zoom';
import './includes/lazy-images';
import './includes/tables';
import './includes/toggle';
import './widgets/accordion';
import './widgets/floating-action-button';
import './widgets/grid';
import './widgets/list-search';
import './widgets/map';
import './widgets/menu';
import './widgets/quantity-selector';
import './widgets/share-menu';
import './widgets/slider';
import './widgets/story-filter';
import './widgets/variant-selector';
import { registerCssBreakpointEvents } from './includes/utils';

registerCssBreakpointEvents();

document.body.addEventListener('contextmenu', function (event) {
    if (event.target instanceof HTMLImageElement) {
        //event.preventDefault();
    }
});
