import PhotoSwipeLightbox from 'photoswipe/dist/umd/photoswipe-lightbox.umd.min';

$(function () {
    const lightbox = new PhotoSwipeLightbox({
        gallery: '.content',
        children: '[data-zoom-src]',
        pswpModule: () => import('photoswipe/dist/umd/photoswipe.umd.min'),
    });
    lightbox.addFilter('domItemData', (itemData, element) => {
        itemData.msrc = element.src;
        itemData.src = element.dataset.zoomSrc;
        itemData.w = Number(element.dataset.zoomWidth);
        itemData.h = Number(element.dataset.zoomHeight);
        itemData.thumbCropped = true;

        return itemData;
    });
    lightbox.init();
});
