import * as Klaro from "klaro/dist/klaro-no-css";

const klaroConfig = {
    htmlTexts: true,
    translations: {
        en: {
            privacyPolicyUrl: '/en/privacy-policy',
            googleAnalytics: {
                title: 'Google Analytics',
                description: 'We use Google Analytics to collect visitor statistics.',
            },
            meta: {
                title: 'Meta',
                description: 'We use the Meta pixel to measure the success of marketing campaigns.',
            },
            linkedIn: {
                title: 'LinkedIn',
                description: 'We use the LinkedIn pixel to measure the success of marketing campaigns.',
            },
            youTube: {
                title: 'YouTube',
                description: 'We use YouTube to embed videos on our website.',
            },
            consentNotice: {
                description: 'We use cookies on our website. Some of them are essential for the operation of the site, while others help us to improve this website and the user experience (tracking cookies). You can decide which cookies you allow. You can change your selection at any time in the <a href="/en/privacy-policy" target="_blank">privacy policy</a>. Please note that if you decline, you may no longer be able to use all the functions of the site.',
                learnMore: 'Settings'
            },
            consentModal: {
                title: 'Cookie settings',
                description: 'Here you will find an overview of all cookies used. You can give your consent to entire categories. Or you can display further information and select only certain cookies.'
            },
            ok: 'Accept',
            decline: 'Decline',
            purposes: {
                analytics: 'Analytics',
                marketing: 'Marketing',
                embeds: 'Content from third parties',
            },
        },
        de: {
            privacyPolicyUrl: '/de/datenschutz',
            googleAnalytics: {
                title: 'Google Analytics',
                description: 'Wir nutzen Google Analytics um Besucherstatistiken zu sammeln.',
            },
            meta: {
                title: 'Meta',
                description: 'Wir nutzen den Meta-Pixel um den Erfolg von Marketing-Kampagnen zu messen.',
            },
            linkedIn: {
                title: 'LinkedIn',
                description: 'Wir nutzen den LinkedIn-Pixel um den Erfolg von Marketing-Kampagnen zu messen.',
            },
            youTube: {
                title: 'YouTube',
                description: 'Wir nutzen YouTube um Videos auf unserer Seite einzubinden.',
            },
            consentNotice: {
                description: 'Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell für den Betrieb der Seite, während andere uns helfen, diese Website und die Nutzererfahrung zu verbessern (Tracking Cookies). Sie können entscheiden, welche Cookies Sie zulassen. Eine Änderung Ihrer Auswahl ist jederzeit in der <a href="/de/datenschutz" target="_blank">Datenschutzerklärung</a> möglich. Bitte beachten Sie, dass bei einer Ablehnung womöglich nicht mehr alle Funktionalitäten der Seite zur Verfügung stehen.',
                learnMore: 'Einstellungen'
            },
            consentModal: {
                title: 'Cookie-Einstellungen',
                description: 'Hier finden Sie eine Übersicht über alle verwendeten Cookies. Sie können Ihre Einwilligung zu ganzen Kategorien geben. Oder Sie lassen sich weitere Informationen anzeigen und können so nur bestimmte Cookies auswählen.'
            },
            ok: 'Akzeptieren',
            decline: 'Ablehnen',
            purposes: {
                analytics: 'Besucherstatistiken',
                marketing: 'Marketing',
                embeds: 'Inhalte von Drittanbietern',
            },
        },
    },
    services: [
        {
            name: 'youTube',
            purposes: ['embeds'],
        },
        {
            name: 'googleAnalytics',
            purposes: ['analytics'],
        },
    ],
    acceptAll: true,
};

['meta', 'linkedIn'].forEach((service) => {
    if (document.querySelector('script[data-name="' + service + '"]')) {
        klaroConfig.services.push({
            name: service,
            purposes: ['marketing'],
        });
    }
});

Klaro.setup(klaroConfig);

[...document.querySelectorAll('.cookie-settings')].forEach((link) => {
    link.addEventListener('click', (event) => {
        event.preventDefault();

        Klaro.show();
    }, true);
});

window.klaro = Klaro;
