import $ from '../vendor/jquery';
import { parse as parseQueryString, stringify as stringifyQueryString } from 'query-string';

$(function () {
    const $body = $(document);

    $('[data-widget="story-filter"]').each(function () {
        const $window = $(window);
        const $container = $('body');
        const $filterContainer = $container.find('[data-target="story-filter.filter-container"]');
        const $filters = $container.find('[data-target="story-filter.filter"]');
        const $items = $container.find('[data-target="story-filter.item"]');
        const $selectedValues = $container.find('[data-target="story-filter.selected-values"]');
        let currentFilter = getStoredFilter();

        function openOverlay($overlay) {
            $overlay.attr('hidden', false);
        }

        function closeOverlay($overlay) {
            $overlay.attr('hidden', true);
        }

        function getUniqueValues($items, field) {
            const values = [];

            $items.not('[hidden]').each((index, element) => {
                const itemValues = $(element).data(field);

                if (Array.isArray(itemValues)) {
                    itemValues.forEach((value) => {
                        if (-1 === values.indexOf(value)) {
                            values.push(value);
                        }
                    });
                }
            });

            return values.sort((a, b) => a.localeCompare(b));
        }

        function applyFilter() {
            currentFilter = getCurrentFilters();

            $filters.each((index, element) => {
                const field = $(element).data('field');
                const $counter = $(element).find('.filters__filter-counter');
                const counter = (currentFilter[field] ?? []).length;

                $counter.text('(' + counter + ')').attr('hidden', 0 === counter);
            });

            $selectedValues.attr('hidden', 0 === Object.keys(currentFilter).length);
            $selectedValues.find('.filters__selected-value').remove();
            for (const [field, values] of Object.entries(currentFilter)) {
                values.forEach((value) => {
                    $selectedValues.append(
                        $('<button type="button" class="filters__selected-value" data-action="story-filter.remove-filter"></button>')
                            .attr('data-field', field)
                            .attr('data-value', value)
                            .text(value),
                    );
                });
            }

            filterItems();
            updateFilterValues();
            saveFilter(currentFilter);
        }

        function removeFilter(field, value) {
            $filters.filter('[data-field="' + field + '"]').find('input[value="' + value + '"]').prop('checked', false);

            applyFilter();
        }

        function reset() {
            $filters.find('input:checked').prop('checked', false);

            applyFilter();
        }

        function getCurrentFilters() {
            const filter = {};

            $filters.each((index, element) => {
                const $filter = $(element);
                const selectedValues = $filter
                    .find('input:checked')
                    .map((index, element) => $(element).val())
                    .get();

                if (0 < selectedValues.length) {
                    filter[$filter.data('field')] = selectedValues;
                }
            });

            return filter;
        }

        function getStoredFilter() {
            try {
                return window.location.hash.match(/^#!/)
                    ? parseQueryString(window.location.hash.substring(2))
                    : {};
            } catch (error) {
                return {};
            }
        }

        function saveFilter(filter) {
            try {
                if (0 < Object.keys(filter).length) {
                    window.location.hash = '!' + stringifyQueryString(filter)
                } else {
                    window.location.hash = '';
                }
            } catch (error) {

            }
        }

        function filterItems() {
            $items.each((index, element) => {
                const $item = $(element);
                const visible = Object.entries(currentFilter)
                    .every((i) => i[1].some((value) => -1 !== ($item.data(i[0]) || []).indexOf(value)));

                $item.attr('hidden', !visible);
            });
        }

        function updateFilterValues() {
            $filters.each(function (filterIndex, element) {
                const $filter = $(element);
                const $options = $filter.find('.filters__filter-options').empty();
                const field = $filter.data('field');

                const values = getUniqueValues($items, field);

                $filter.attr('hidden', 0 === values.length);

                values.forEach((value, optionIndex) => {
                    const inputId = 'story-filter-option-' + filterIndex + '-' + optionIndex;

                    $('<div class="filters__filter-option"/>')
                        .append(
                            $('<input type="checkbox"/>')
                                .attr('id', inputId)
                                .attr('checked', -1 !== (currentFilter[field] ?? []).indexOf(value))
                                .val(value),
                        )
                        .append(
                            $('<label/>')
                                .attr('for', inputId)
                                .text(value),
                        )
                        .appendTo($options);
                });
            });

            $filterContainer.attr('hidden', 0 === $filters.not('[hidden]').length);
        }

        updateFilterValues();

        $filters.each(function (filterIndex, element) {
            const $filter = $(element);
            const $overlay = $filter.find('.filters__filter-overlay');
            const $options = $overlay.find('.filters__filter-options');

            $filter
                .on('click', '[data-action="story-filter.toggle"]', function (event) {
                    if ($overlay.attr('hidden')) {
                        openOverlay($overlay);
                    } else {
                        closeOverlay($overlay);
                    }

                    event.preventDefault();
                    event.currentTarget.blur();
                })
                .on('click', '[data-action="story-filter.close"]', function (event) {
                    closeOverlay($overlay);

                    event.preventDefault();
                    event.currentTarget.blur();
                })
                .on('click', '[data-action="story-filter.delete-all"]', function (event) {
                    $options.find('input:checked').prop('checked', false);
                    applyFilter();
                    closeOverlay($overlay);

                    event.preventDefault();
                    event.currentTarget.blur();
                })
                .on('click', '[data-action="story-filter.apply"]', function (event) {
                    applyFilter();
                    closeOverlay($overlay);

                    event.preventDefault();
                    event.currentTarget.blur();
                });

            $body.on('click', (event) => {
                if (
                    !$overlay.attr('hidden')
                    && 0 === $filter.has(event.target).length
                ) {
                    closeOverlay($overlay);
                }
            });
        });

        $container
            .on('click', '[data-action="story-filter.reset"]', function (event) {
                reset();

                event.preventDefault();
                event.currentTarget.blur();
            })
            .on('click', '[data-action="story-filter.remove-filter"]', function (event) {
                const $button = $(event.currentTarget);

                removeFilter($button.data('field'), $button.data('value'));

                event.preventDefault();
                event.currentTarget.blur();
            });

        $window.on('hashchange', function () {
            currentFilter = getStoredFilter();
            updateFilterValues();
            applyFilter();
        })

        applyFilter();
    });
});
