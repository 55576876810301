import $ from '../vendor/jquery';

$(function () {
    $('body').on('click', '[data-action="toggle"]', function (event) {
        const $element = $(this),
            selector = $element.attr('href'),
            classPrefix = $element.data('classPrefix') || '';

        event.preventDefault();

        $(selector).toggleClass(classPrefix + 'hidden ' + classPrefix + 'visible');
    });
});
